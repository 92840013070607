import './App.scss';
import {ReactComponent as InstagramLogo} from './assets/instagram.svg';
import {ReactComponent as TwitterLogo} from './assets/twitter.svg';
import {ReactComponent as TiktokLogo} from './assets/tiktok.svg';
import {ReactComponent as EmailLogo} from './assets/email.svg';

function App() {
  return (
    <div className="App">
      <header className="Header">
        <h1>Coming soon</h1>
        <div className="SocialBar">
          <a href="https://www.instagram.com/eloasnowe/"><InstagramLogo className="SocialIcon" /></a>
          <a href="https://twitter.com/EloaSnowe"><TwitterLogo className="SocialIcon" /></a>
          <a href="https://www.tiktok.com/@eloasnowe"><TiktokLogo className="SocialIcon" /></a>
          <a href="mailto:inquiries@snowe.ca"><EmailLogo className="SocialIcon" /></a>
          
        </div>

      </header>
    </div>
  );
}

export default App;
